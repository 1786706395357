import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';

const BulletLists = ({ data, className }) => {
  const { lists } = data;

  return (
    <Section className={cn('bullet-lists', [className])}>
      <Container>
        <Row>
          <Column lg="6" col="12" className="offset-lg-1">
            <Row>
              {lists.map(({ title, list }, index) => (
                <Column
                  className="bullet-lists__item"
                  lg="4"
                  sm="6"
                  col="12"
                  key={index}
                >
                  <Text className="bullet-lists__item__title body-sm_bold">
                    {title}
                  </Text>

                  <ul className="bullet-lists__item__list">
                    {list.split(/\n/).map((element, index) => (
                      <li className="bullet-lists__item__list-item" key={index}>
                        <Text tag="span" className="body-sm body-sm_thin">
                          {element}
                        </Text>
                      </li>
                    ))}
                  </ul>
                </Column>
              ))}
            </Row>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

BulletLists.defaultProps = {
  className: '',
};

BulletLists.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default BulletLists;
